
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
library.add(fas)
library.add(fab)

export default {
	name: 'WhitelabelLayout',
	data() {
		return {
			showLoginModal: false
		}
	},
	mounted() {
		window.addEventListener('message', this.handleMessage);
	},

	beforeDestroy() {
		window.removeEventListener('message', this.handleMessage);
	},
	methods: {
		handleModalClose() {
			console.log('handleModalClose triggered');
			this.showLoginModal = false;
		},
		handleReload() {
			console.log('handleReload');
		},
		handleMessage(event) {
			if (event.data.type === 'LOGOUT') {
				this.$member.auth.logout();
				window.parent.postMessage({ type: 'LOGOUT_COMPLETE' }, '*');
			}
			if (event.data.type === 'SHOW_LOGIN') {
				console.log('SHOW_LOGIN event received');
				this.showLoginModal = true;

			}
		}
	}
}
