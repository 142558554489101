

export default {
    props: {
        dropdown: {
            type: Boolean,
            default: false
        },
        items: Array,
        logoutOption: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        async logout() {
            this.$member.auth.logout();
            location.reload();
        }
    }
}
