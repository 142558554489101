
export default {
    props: {
        title: String,
        model: String,
        size: String,
        cardColor: String,
        centered: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (process.client) {
                // Scroll to top when modal is mounted
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
                
                // Disable body scrolling when modal is open
                document.body.style.overflow = 'hidden';
            }
        });
    },
    beforeDestroy() {
        if (process.client) {
            // Re-enable body scrolling when modal is closed
            document.body.style.overflow = '';
        }
    },
    methods: {
        closeModal() {
            if (!this.model) this.$emit('close');
            this.$parent[this.model] = 'false';
        }
    }
}
