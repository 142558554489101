// store/member.js

export const state = () => ({
  member: {},
  notifications_count: 0,
  player_configuration: {},
  pinForm: {},
  auth_token: null,
  skipRefreshOnLoad: [],
  currentPin: null
});

export const mutations = {
  setField(state, { field, data }) {
    state.member[field] = data;
  },
  updateMember(state, member) {
    for (const key in member) {
      if (member[key] !== null && member[key] !== undefined) {
        state.member[key] = member[key];
      }
    }
  },
  setAuthToken(state, token) {
    state.auth_token = token;
  },
  setCurrentPin(state, pin) {
    state.currentPin = pin;
  },
  setPinForm(state, config) {
    state.pinForm = config
  },
  setPlayerConfiguration(state, playerConfiguration) {
    state.player_configuration = playerConfiguration;
  }
};

export const getters = {
  getField: (state) => (field) => {
    return state.member[field] ? { ...state.member[field], source: 'vuex' } : undefined;
  },
  getPlayerConfiguration: (state) => {
    return state.player_configuration;
  },
  getAuthToken(state) {
    return state.auth_token;
  },
  getPinForm(state) {
    return state.pinForm
  }
};

function safeJSONParse(input) {
  if (typeof input === 'string') {
    try {
      return JSON.parse(input);
    } catch (error) {
      return null;
    }
  } else {
    return input;
  }
}

function extractFieldsFromObject(fields, object) {
  let parsedObject = object;
  if (typeof object === 'string') {
    parsedObject = safeJSONParse(object);
    if (parsedObject === null) {
      return null;
    }
  }
  const results = {};
  fields.forEach(field => {
    if (field in parsedObject) {
      results[field] = parsedObject[field];
    }
  });
  return results;
}

export const actions = {

  async logout({ commit, state }, { $axios, $cookies }) {
    console.log('logging out from store')
    $cookies.remove('auth_token', {path: '/', domain: '.truefire.com', secure: false});
    $cookies.remove('server_auth_token', {domain: '.truefire.com'});
    $cookies.remove('tfs_auth_token', {domain: '.truefire.com'});
    $cookies.remove('member-data', {path: '/', domain: '.truefire.com', secure: false});
    $cookies.remove('player_config', {path: '/', domain: '.truefire.com', secure: false});
    $cookies.remove('cookie_auth_user',{path: '/', domain: '.truefire.com', secure: false});
    commit('setAuthToken', null);
    commit('updateMember', {});
    localStorage.clear();
    sessionStorage.clear();
  },

  async login({ commit, state }, { authentication, $axios, $cookies }) {

    let nexusObject = {
      member: {
        login: {
          username: authentication.username,
          password: authentication.password,
          remember_me: authentication.remember_me
        },
        fields: ['memberid']
      }
    };
    try {
      await $axios.post(process.env.nexusUrl, nexusObject);
      return false;
    } catch (error) {
      console.error('Error during member login:', error);
      if (error.response && error.response.data && error.response.data.message) {
        return error.response.data.message;
      }
      else if (error.message) return error.message;
      else return error;
    }

  },
  async checkForExistingEmail({ commit, state }, { email, $axios, $cookies }) {
    const postObject = {
      member:{
        check_email: {
          email: email
        }
      }
    };
    return await $axios.post(process.env.nexusUrl, postObject);
  },
  async validateEmailWithPinAndKey({ commit, state }, { email, pin, key, $axios, $cookies }) {
    let nexusObject = {
      member:{
        validate_pin:{
          pin: pin,
          key: key,
        },
        fields: ['memberusername','token']
      }
    };
    return await $axios.post(process.env.nexusUrl, nexusObject);

  },
  async sendPinToEmail({ commit, state }, { email, key, $axios, $cookies }) {
    let nexusObject = {
      member:{
        send_pin_email:{
          email: email,
          key: key
        }
      }
    };
    return await $axios.post(process.env.nexusUrl, nexusObject);
  },
  async register({ commit, state }, { registration, $axios, $cookies }) {
    //are we registering with credentials

    let joinObject = {
      username: registration.username || null,
      email: registration.email,
      password: registration.password,
      subscribe_to_educator: registration.subscribe_to_educator,
      subscribe_to_truefire: registration.subscribe_to_truefire
    };

    if (registration.bypass_trial !== undefined) {
      joinObject.bypass_trial = registration.bypass_trial;
    }

    if (registration.send_redirect !== undefined) {
      joinObject.send_redirect = registration.send_redirect;
    }

    if ($cookies.get('plus_network_id')) {
      joinObject.plus_network_id = $cookies.get('plus_network_id');
    }

    let nexusObject = {
      member: {
        join: joinObject,
        fields: ['memberid']
      }
    };
    if (registration.email_opted_in) nexusObject.member.join.email_opted_in = true;

    try {
      await $axios.post(process.env.nexusUrl, nexusObject);
      return false;
    } catch (error) {
      console.error('Error registering member:', error);
      if (error.response && error.response.data && error.response.data.message) {
        return error.response.data.message;
      }
      else if (error.message) return error.message;
      else return error;
    }
  },
  async getPlayerConfiguration({ commit, state }, { $axios, $cookies }) {
    //check store first
    if (state.player_configuration) {
      return state.player_configuration;
    }
    const response = await $axios.post(process.env.nexusUrl, {
      member: { from_nonce: { nonce: nonce }, fields: ['player_configuration'] }
    });
    if (typeof response.data.member.player_configuration !== 'undefined') {
      commit('setPlayerConfiguration', response.data.member.player_configuration);
      return true;
    }
    


  },
  async getFields({ commit, state }, { fields, $axios, $cookies }) {
    const authToken = $cookies.get('auth_token');
    if (!authToken) {
      return {};
    }
    commit('setAuthToken', authToken);

    let fieldsArray = Array.isArray(fields) ? fields : [fields];
    let storedObject = state.member;

    let extractedFields = extractFieldsFromObject(fieldsArray, storedObject);
    let missingFields = fieldsArray.filter(field => !(field in extractedFields && extractedFields[field] != null));
    console.log('missing fields', missingFields)
    console.log('extracted fields', extractedFields)
    console.log('stored object', storedObject)
    if (missingFields.length === 0) {
      return Array.isArray(fields) ? extractedFields : extractedFields[fields];
    }

    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { fields: missingFields }
      });

      if (response.status === 200 && response.data.member) {
        commit('updateMember', response.data.member);
        extractedFields = extractFieldsFromObject(fieldsArray, response.data.member);

        return Array.isArray(fields) ? extractedFields : extractedFields[fields];
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  },
  // Notifications
  async getNotificationsCount({ commit, state }, { $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { fields: ['unread_notification_count'] }
      });
      const count = response.data.member.unread_notification_count;
      return count;
    } catch (error) {
      return 0;
    }
  },
  async getNotifications({ commit, state }, { $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { fields: ['notifications'] }
      });
      return response.data.member.notifications;
    } catch (error) {
      console.error('Error fetching notifications:', error);
      return [];
    }
  },
  async markNotificationsAsRead({ commit, state }, { notificationIds, $axios, $cookies }) {
    if (!notificationIds) {
      notificationIds = [];
    }
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { mark_notifications_as_read: notificationIds }
      });
      return {};
    } catch (error) {
      console.error('Error marking notifications as read:', error);
      return {};
    }
  },
  // Collections
  async getCollections({ commit, state }, { $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { no_cache: true, fields: ['collections'] }
      });
      return response.data.member.collections;
    } catch (error) {
      console.error('Error fetching collections:', error);
      return [];
    }
  },
  async addCollection({ commit, state }, { name, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { add_collection: name, fields: ['collections'] }
      });
      return true;
    } catch (error) {
      console.error('Error adding collection:', error);
      return false;
    }
  },
  async addCourseToCollection({ commit, state }, { collectionId, courseId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: {
          add_course_to_collection: { collection_id: collectionId, course_id: courseId }, fields: [
            'collections',
            {
              'collections_for_item': {
                'collections_for_item': courseId
              }
            }
          ]
        }
      });
      return true;
    } catch (error) {
      console.error('Error adding course to collection:', error);
      return false;
    }
  },
  async removeCourseFromCollection({ commit, state }, { collectionId, courseId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { remove_course_from_collection: { collection_id: collectionId, course_id: courseId }, fields: ['collections'] }
      });
      return true;
    } catch (error) {
      console.error('Error removing course from collection:', error);
      return false;
    }
  },
  async removeCollection({ commit, state }, { collectionId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { remove_collection: collectionId, fields: ['collections'] }
      });
      return true;
    } catch (error) {
      console.error('Error removing collection:', error);
      return false;
    }
  },
  async getCollectionsForItem({ commit, state }, { itemId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: {
          "no_cache": true,
          fields: [
            {
              'collections_for_item': {
                'collections_for_item': itemId
              }
            }]
        }
      });
      return response.data.member.collections_for_item;
    } catch (error) {
      console.error('Error fetching collections for item:', error);
      return [];
    }
  },
  async getCollection({ commit, state }, { collectionId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { fields: { collection: { collection: collectionId } } }
      });
      return response.data.member.collection;
    } catch (error) {
      console.error('Error fetching collection:', error);
      return [];
    }
  },
  async archiveItem({ commit, state }, { itemId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { archive_collection_item: itemId, fields: ['collections'] }
      });
      return true;
    } catch (error) {
      console.error('Error archiving item:', error);
      return false;
    }
  },
  async removeArchivedItem({ commit, state }, { itemId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { remove_archived_collection_item: itemId, fields: ['collections'] }
      });
      return true;
    } catch (error) {
      console.error('Error removing archived item:', error);
      return false;
    }
  },
  async getAvailableCollectionsForItem({ commit, state }, { itemId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { fields: [{ 'available_collections_for_item': { 'available_collections_for_item': itemId } }] }
      });
      return response.data.member.available_collections_for_item;
    } catch (error) {
      console.error('Error fetching available collections for item:', error);
      return [];
    }
  },
  async addCourseToFavorites({ commit, state }, { courseId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { add_favorite_course: courseId, fields: ['memberid'] }
      });
      return true;
    } catch (error) {
      console.error('Error adding course to favorites:', error);
      return false;
    }
  },
  async removeCourseFromFavorites({ commit, state }, { courseId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { remove_favorite_course: courseId, fields: ['memberid'] }
      });
      return true;
    } catch (error) {
      console.error('Error removing course from favorites:', error);
      return false;
    }
  },
  async addEducatorToFavorites({ commit, state }, { educatorId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { add_favorite_educator: educatorId, fields: ['memberid'] }
      });
      return true;
    } catch (error) {
      console.error('Error adding educator to favorites:', error);
      return false;
    }
  },
  async removeEducatorFromFavorites({ commit, state }, { educatorId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { remove_favorite_educator: educatorId, fields: ['memberid'] }
      });
      return true;
    } catch (error) {
      console.error('Error removing educator from favorites:', error);
      return false;
    }
  },
  async loginMemberFromNonce({ commit, state }, { nonce, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { from_nonce: { nonce: nonce }, fields: ['memberid', 'memberusername', 'token'] }
      });
      $cookies.set('auth_token', response.data.member.token);
      $cookies.set('server_auth_token', response.data.member.token);
      $cookies.set('tfs_auth_token', response.data.member.token);
      commit('setAuthToken', response.data.member.token);
      return response.data.member;

    } catch (error) {
      return false;
    }
  },

  async getMemberFromPartnershipNonce({ commit, state }, { nonce, partnerId, $axios, $cookies }) {
    try {
      // if (partnerId) {
      //   const response = await $axios.post(process.env.nexusUrl, {
      //     "partner": { id: partnerId, fields: ['player_configuration'] },
      //   });
      //   if (typeof response.data.partner.player_configuration !== 'undefined') {
      //     // $cookies.set('player_config', Buffer.from(JSON.stringify(response.data.partner.player_configuration)).toString('base64'))
      //     localStorage.setItem('partner_client_key', response.data.partner.client_key);
      //     commit('setPlayerConfiguration', response.data.partner.player_configuration);
      //   }
      //   return true;
      // }
      const response = await $axios.post(process.env.nexusUrl, {
        member: { from_nonce: { nonce: nonce }, fields: ['player_configuration','token','memberemail'] }
      });
      if (typeof response.data.member.player_configuration !== 'undefined') {
        commit('setPlayerConfiguration', response.data.member.player_configuration);
        return true;
      }
    } catch (error) {
      console.error('Unable to get member from nonce:', error);
      return false;
    }
  },

  async resendPinEmailFromFormKey({ commit, state }, { formKey, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { resend_pin_email_from_key: { key: formKey } }
      });
      return true;
    } catch (error) {
      console.error('Unable to resend pin email from form key:', error);
      return false;
    }
  },

  async getMemberFromPinAndFormKey({ commit, state }, { pin, formKey, $axios, $cookies }) {
    try {
      console.log('my pin for post is', pin)
      console.log('my key for post is', formKey)
      const response = await $axios.post(process.env.nexusUrl, {
        member: { from_pin_and_key: { pin: pin, key: formKey }, fields: ['player_configuration'] }
      });
      if (typeof response.data.member.player_configuration !== 'undefined') {
        $cookies.set('player_config', Buffer.from(JSON.stringify(response.data.member.player_configuration)).toString('base64'))
        commit('setPlayerConfiguration', response.data.member.player_configuration);
      }
    } catch (error) {
      console.error('Unable to get member from pin and key:', error);
      return false;
    }
  },

  async getMemberData({ commit, state, $axios, $cookies }, { fields }) {
    const authToken = $cookies.get('auth_token');
    if (!authToken) {
      console.warn('No auth token found, skipping Axios call');
      return;
    }

    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { fields }
      });

      if (response.status === 200 && response.data.member) {
        commit('updateMember', response.data.member);
        $cookies.set('member-data', JSON.stringify(state.member), {
          path: '/',
          domain: '.truefire.com',
          maxAge: 60 * 60 * 24 // 24 hours
        });
      } else {
        console.error('Non-200 status code received:', response.status);
      }
    } catch (error) {
      console.error('Error fetching member data:', error);
    }
  },

  async checkPlayerConfiguration({ commit, state }, { $cookies }) {
    if(state.player_configuration) {
      return true;
    }
    const response = await $axios.post(process.env.nexusUrl, {
      member: { from_nonce: { nonce: nonce }, fields: ['player_configuration'] }
    });
    if (typeof response.data.member.player_configuration !== 'undefined') {
      commit('setPlayerConfiguration', response.data.member.player_configuration);
      return true;
    }
  },
  async checkOutCart({ commit, state }, { checkOutObject, $axios, $cookies }) {

    const response = await $axios.post(process.env.nexusUrl, {
      member: {
        cart_checkout: checkOutObject,
        fields: ['memberid']
      }
    });
    return response;

  },
  async getPaymentProfiles({ commit, state }, { $axios, $cookies }) {
    if(!state.auth_token) {
      return [];
    }
    const response = await $axios.post(process.env.nexusUrl, {
      member: {
        fields: ['payment_profiles']
      }
    });
    if(response.data.member.payment_profiles) {
      return response.data.member.payment_profiles;
    }
    return false;
  },
  async setCartItems({ commit, state }, { cartItems, $axios, $cookies }) {

    let cart_items = [];
    cartItems.forEach(item => {
      cart_items.push({
        id: item
      });
    });

    const response = await $axios.post(process.env.nexusUrl, {
      member: {
        cart_add_items: cart_items,
        fields: ['cart']
      }
    });

    return response;
  },
  async getCart({ commit, state }, { $axios, $cookies }) {
    console.log('getting cart')
  },
  updateSkipRefreshOnLoad({ commit }, skipRefreshOnLoad) {
    commit('updateSkipRefreshOnLoad', skipRefreshOnLoad);
  }
}