export default async function ({ $cookies, route, store }) {

    if($cookies.get('plus_network_id')){
        try{
          store.commit("configs/setPlusNetworkId", $cookies.get('plus_network_id'));
        }catch(e){
          console.error('Error setting plus_network_id from cookie', e);
        }
      }
    
      // add plus_network_id to cookies to trigger E+ layout
      if (route.query.educator_id) {
        console.log('Setting plus_network_id from query param:', route.query.educator_id);
        $cookies.set('plus_network_id', route.query.educator_id, {
           path: '/',
           expires: new Date(new Date().getTime() + 60 * 60 * 24 * 30 * 1000),
           maxAge: 60 * 60 * 24 * 30,
           sameSite: 'lax',
           priority: 'high'
        });
    
        store.commit("configs/setPlusNetworkId", route.query.educator_id);
    
      }
}
  