const middleware = {}

middleware['header'] = require('../middleware/header.js')
middleware['header'] = middleware['header'].default || middleware['header']

middleware['memberDataRefresh'] = require('../middleware/memberDataRefresh.js')
middleware['memberDataRefresh'] = middleware['memberDataRefresh'].default || middleware['memberDataRefresh']

middleware['plusnetwork'] = require('../middleware/plusnetwork.js')
middleware['plusnetwork'] = middleware['plusnetwork'].default || middleware['plusnetwork']

middleware['tfs'] = require('../middleware/tfs.js')
middleware['tfs'] = middleware['tfs'].default || middleware['tfs']

export default middleware
