import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0742d547 = () => interopDefault(import('../pages/25th.vue' /* webpackChunkName: "pages/25th" */))
const _2a86874c = () => interopDefault(import('../pages/browse.vue' /* webpackChunkName: "pages/browse" */))
const _045b8f4d = () => interopDefault(import('../pages/directory.vue' /* webpackChunkName: "pages/directory" */))
const _8e21d5dc = () => interopDefault(import('../pages/online-guitar-lessons.vue' /* webpackChunkName: "pages/online-guitar-lessons" */))
const _c04466aa = () => interopDefault(import('../pages/online-guitar-lessons-cc.vue' /* webpackChunkName: "pages/online-guitar-lessons-cc" */))
const _5ee804a7 = () => interopDefault(import('../pages/sandbox.vue' /* webpackChunkName: "pages/sandbox" */))
const _3a6842c6 = () => interopDefault(import('../pages/partnerships/directory.vue' /* webpackChunkName: "pages/partnerships/directory" */))
const _60580ae2 = () => interopDefault(import('../pages/partnerships/page.vue' /* webpackChunkName: "pages/partnerships/page" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4002703f = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/25th",
    component: _0742d547,
    name: "25th"
  }, {
    path: "/browse",
    component: _2a86874c,
    name: "browse"
  }, {
    path: "/directory",
    component: _045b8f4d,
    name: "directory"
  }, {
    path: "/online-guitar-lessons",
    component: _8e21d5dc,
    name: "online-guitar-lessons"
  }, {
    path: "/online-guitar-lessons-cc",
    component: _c04466aa,
    name: "online-guitar-lessons-cc"
  }, {
    path: "/sandbox",
    component: _5ee804a7,
    name: "sandbox"
  }, {
    path: "/partnerships/directory",
    component: _3a6842c6,
    name: "partnerships-directory"
  }, {
    path: "/partnerships/page",
    component: _60580ae2,
    name: "partnerships-page"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/*",
    component: _4002703f,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
