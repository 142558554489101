export default function ({ store, $axios, $cookies, req }, inject) {
    
    async function get(id) {
        return await store.dispatch('configs/fetchConfigById', { id, $axios, $cookies });
    }

    async function nav(type) {
        return await store.dispatch('configs/getNav', { type, $axios, $cookies });
    }
    
    function setNavCache(data) {
        return store.dispatch('configs/setNavCookie', { data, $cookies });
    }

    async function getNavCache() {
        return await store.dispatch('configs/getNavCookie', { $cookies });
    }

    async function plusNetwork() {
        return await store.dispatch('configs/getPlusNetwork');
    }

    async function getPlayerConfigurationFromPartnerId(partnerId) {
        return await store.dispatch('configs/getPlayerConfigurationFromPartnerId', { partnerId, $axios, $cookies });
    }

    inject('configs', { get, nav, getNavCache, setNavCache, plusNetwork, getPlayerConfigurationFromPartnerId });
}
